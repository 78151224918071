import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Checkout from "./Pages/Checkout";
import Order from "./Pages/Order";

const StyledContainer = styled(Container)`
  height: 100vh;
  background-color: #dce1d9;
  color: #343d30;
`;

function App() {
  return (
    <Router>
      <StyledContainer fluid>
        <Switch>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/order/:referenceNumber">
            <Order />
          </Route>
          <Redirect from="/" to="/checkout" exactt />
        </Switch>
      </StyledContainer>
    </Router>
  );
}

export default App;
