import React from "react";
import axios from 'axios';
import styled from "styled-components";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card
} from "reactstrap";
import Select from "react-select";
import logo from "../assets/logo.png";
import security from "../assets/security.png";

const FormContainer = styled(Card)`
  width: 550px;
  position: absolute;
  top: 50vh;
  width: 50vw;
  transform: translate(50%, -50%);
  padding: 30px;
  overflow: auto;
  max-height: 95vh;
`;

const StyledLogo = styled.img`
  height: 80px;
  margin-right: 10px;
`; 

const StyledImg = styled.img`
  height: 50px;
  position: absolute;
  right: 0;
  margin-right: 15px;
  opacity: 0.4;
`; 

const Inline = styled.div`
  display: inline-flexbox;
  vertical-align: middle;
  color: #666666;
`;

const SecurityInline = styled.div`
  display: flexbox;
  width: 100%;
  justify-content: right;
`;

const BigCaption = styled.p`
  color: #444444;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const SmallCaption = styled.p`
  color: #777777;
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
`;

const options = [
  { value: 6, label: "6 months" },
  { value: 9, label: "9 months" }
];

const priceOptions = [
  { value: 1000, label: "1000" },
  { value: 2000, label: "2000" },
  { value: 3000, label: "3000" },
  { value: 4000, label: "4000" },
  { value: 5000, label: "5000" },
  { value: 6000, label: "6000" }
];

const countryOptions = [
  { value: 'AU', label: 'Australia'},
  { value: 'NZ', label: 'New Zealand'}
];

class Checkout extends React.Component {

  state = {
    free_period_month: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    price: '',
    address: '',
    address2: '',
    city: '',
    province: '',
    country: '',
    zipcode: '',
  }

  handleChangeInput = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  handleChangeSelect = (name) => (data) => {
    this.setState({ [name]: data });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      ...this.state,
      free_period_month: this.state.free_period_month.value,
      price: this.state.price.value,
      country: this.state.country.value
    }
    axios.post(`https://checkout.api.naturalfertilitybreakthrough.com/checkout`, params)
      .then(res => {
        console.log(res.data.data.url)
        window.location.replace(res.data.data.url)
      })
  }

  render() {
    const { free_period_month,
    first_name,
    last_name,
    email,
    mobile_number,
    price,
    address,
    address2,
    city,
    province,
    country,
    zipcode} = this.state;
  return (
    <Row>
      <Col md={12}>
        <FormContainer>
          <Row>
            <Col md="6">
              <Inline><StyledLogo src={logo} alt="nf logo" /></Inline>
              <Inline><h4>Natural Fertility<br/>Breakthrough</h4></Inline>
            </Col>
            <Col md="6">
              <SecurityInline>
              <StyledImg src={security} alt="security" />
              </SecurityInline>
            </Col>
          </Row>
          <hr />
          <BigCaption>
            Congratulations in making the decision to join the Natural Fertility Breakthrough Program!
          </BigCaption>
          <SmallCaption>
            Please complete the form below to finalise your payment with ZipMoney
            <br />
          </SmallCaption>
          <Form onSubmit={this.handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label><b>Interest Free Period</b></Label>
                  <Select options={options} value={free_period_month} onChange={this.handleChangeSelect('free_period_month')} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label><b>Price</b></Label>
                  <Select options={priceOptions} value={price} onChange={this.handleChangeSelect('price')} />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label><b>Name</b></Label>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="text" value={first_name} onChange={this.handleChangeInput('first_name')} placeholder="First Name" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="text" value={last_name} onChange={this.handleChangeInput('last_name')} placeholder="Last Name" required />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label><b>Contact Information</b></Label>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="email" value={email} onChange={this.handleChangeInput('email')} placeholder="Email Address" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="number" value={mobile_number} onChange={this.handleChangeInput('mobile_number')} placeholder="Mobile Phone" required />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Label><b>Address</b></Label>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Input type="text" value={address} onChange={this.handleChangeInput('address')} placeholder="Street Address" required />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Input type="text" value={address2} onChange={this.handleChangeInput('address2')} placeholder="Address Line 2" required />
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Input type="text" value={city} onChange={this.handleChangeInput('city')} placeholder="City" required />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Input type="number" value={zipcode} onChange={this.handleChangeInput('zipcode')} placeholder="Zip Code" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input type="text" value={province} onChange={this.handleChangeInput('province')} placeholder="State/Province" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Select options={countryOptions} value={country} onChange={this.handleChangeSelect('country')} />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Button
                  className="float-right"
                  size="lg"
                  type="submit"
                  color="success"
                >
                  Checkout
                </Button>
              </Col>
            </Row>
          </Form>
        </FormContainer>
      </Col>
    </Row>
  );
  }
}

export default Checkout;
