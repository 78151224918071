import { withRouter } from 'react-router-dom'
import React from "react";
import axios from 'axios';
import styled from "styled-components";
import { Row, Col, Card } from "reactstrap";
import logo from "../assets/logo.png";
import security from "../assets/security.png";

const FormContainer = styled(Card)`
  width: 550px;
  position: absolute;
  top: 50vh;
  width: 50vw;
  transform: translate(50%, -50%);
  padding: 30px;
`;

const StyledLogo = styled.img`
  height: 80px;
  margin-right: 10px;
`;

const StyledImg = styled.img`
  height: 50px;
  position: absolute;
  right: 0;
  margin-right: 15px;
  opacity: 0.4;
`;

const Inline = styled.div`
  display: inline-flexbox;
  vertical-align: middle;
  color: #666666;
`;

const SecurityInline = styled.div`
  display: flexbox;
  width: 100%;
  justify-content: right;
`;

const StyledTitle = styled.h3`
  color: green;
  margin-bottom: 25px;
`;

const StyledTitleFailed = styled.h3`
  color: red;
  margin-bottom: 25px;
`;

const StyledText = styled.p``;

class Order extends React.Component {

  getParams (data) {
    try {
    if (data) {
      const parseData = (args) => {
        return JSON.parse(
          `{"${decodeURI(args)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')}"}`
        );
      };
      if (data.indexOf('?') === 0) {
        let meow = decodeURIComponent(data);
        const newData = meow.replace('?', '');
        return parseData(newData);
      }
      return parseData(data);
    }
    return {};
  } catch (err) {
    return {};
  }

  };

  componentDidMount() {
    this.updateOrder();
  }

  updateOrder = () => {
    const urlParams = this.getParams(this.props.location.search);
    const referenceNumber = this.props.match.params.referenceNumber
    const params = {
      status: urlParams.result,
      zipmoney_reference: urlParams.checkoutId
    }
    axios.put(`https://checkout.api.naturalfertilitybreakthrough.com/checkout/${referenceNumber}`, params)
      .then(res => {
        console.log(res.data.message)
      })
  }

  render () {

   const urlParams = this.getParams(this.props.location.search);
   if (urlParams.result !== 'succeed') {
    return (
      <Row>
        <Col md={12}>
          <FormContainer>
            <Row>
              <Col md="6">
                <Inline>
                  <StyledLogo src={logo} alt="nf logo" />
                </Inline>
                <Inline>
                  <h4>
                    Natural Fertility
                    <br />
                    Breakthrough
                  </h4>
                </Inline>
              </Col>
              <Col md="6">
                <SecurityInline>
                  <StyledImg src={security} alt="security" />
                </SecurityInline>
              </Col>
            </Row>
            <hr />
            <StyledTitleFailed>Payment failed!</StyledTitleFailed>
            <StyledText>
              Please try again later or if there are issues with your ZipMoney account, please contact ZipMoney here: https://help.zip.co/en.
            </StyledText>
            <StyledText>
              If you need further assistance from our team, please call us on +61 2 8074 3939 or 1300 85 84 90. You can also email us at info@naturalfertilitybreakthrough.com
            </StyledText>
          </FormContainer>
        </Col>
      </Row>
    );
   }

   return (
      <Row>
        <Col md={12}>
          <FormContainer>
            <Row>
              <Col md="6">
                <Inline>
                  <StyledLogo src={logo} alt="nf logo" />
                </Inline>
                <Inline>
                  <h4>
                    Natural Fertility
                    <br />
                    Breakthrough
                  </h4>
                </Inline>
              </Col>
              <Col md="6">
                <SecurityInline>
                  <StyledImg src={security} alt="security" />
                </SecurityInline>
              </Col>
            </Row>
            <hr />
            <StyledTitle>Payment succeed!</StyledTitle>
            <StyledText>
              Congratulations and welcome to the Natural Fertility Breakthrough Program. We are thrilled we get to hold your hand on this journey.
            </StyledText>
            <StyledText>
              We are here to serve you and want to do all we can to help you create the healthy baby of your dreams - so please be sure to reach out if there is anything else we can do to assist you along the way.
            </StyledText>
            <StyledText>
              Look out for those 2 emails we will be sending through. One comes with the sender name of 'Hellosign' and the other from 'Reception' and they contain everything you need to do so we can all get started preparing for your first session and beyond.
            </StyledText>
            <StyledText>
              If you have not received the emails in the next 30 minutes, please contact info@naturalfertilitybreakthrough.com or call us on 1300 858 490 so that we can further assist you.
            </StyledText>
          </FormContainer>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Order);
